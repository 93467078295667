body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
  font-size: 14px;
}


a {
  font-size: 14px;
  font-weight: 500;
}

/* List page, Details view titles */
h1 {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

/* Modals header */
h2 {
  font-size: 18px;
  padding-left: 3px;
  font-weight: 400;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 5px;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
} 

/* Details view subtitle */
h5 {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

/* Subtitle for details page actions */
h6 {
  font-weight: 500;
  font-size: 12px;
  color: #313131;
  text-align: center;
  width: fit-content;
  white-space: nowrap;
  margin: 0
}

/* Property name label (Details views) */
label {
  font-style: italic; 
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  display: block;
}

form label {
  margin: 0;
  font-style: normal;
}

.MuiFormLabel-root {
  margin: 0;
  font-style: normal;
}


.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}